import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "root"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_router_link = _resolveComponent("custom-router-link")!
  const _component_check_roles = _resolveComponent("check-roles")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_x4b_ui = _resolveComponent("x4b-ui")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_x4b_ui, {
      ref: "x4bui",
      application: "viv-wallet",
      "apps-service-url": _ctx.appsUrl,
      version: _ctx.appVersion,
      "disable-fake-elements": "true",
      languages: "fr",
      color: _ctx.primaryColor
    }, {
      default: _withCtx(() => [
        (_ctx.isBannerInitialized)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(['menu', _ctx.isMenuOpen ? '' : 'hidden'])
              }, [
                _createVNode(_component_check_roles, { roles: _ctx.myWalletRoles }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_router_link, { to: "/wallet" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Mon wallet")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["roles"]),
                _createVNode(_component_check_roles, { roles: _ctx.expertisesRoles }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_router_link, { to: "/members" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Expertises")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["roles"]),
                _createVNode(_component_check_roles, { roles: _ctx.walletsRoles }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_router_link, { to: "/wallets" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Wallets")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["roles"]),
                _createVNode(_component_check_roles, { roles: _ctx.historyRoles }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_router_link, { to: "/actions" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Historique des actions")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["roles"])
              ], 2),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4))
      ]),
      _: 1
    }, 8, ["apps-service-url", "version", "color"])
  ]))
}