import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isAuthorized())
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_ctx.withErrorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Not authorized"))
      : _createCommentVNode("", true)
}