import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "aria-current", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    custom: ""
  }, {
    default: _withCtx(({ route, href, navigate }) => [
      _createElementVNode("a", {
        href: href,
        class: _normalizeClass(_ctx.isActive() ? 'router-link-active' : ''),
        "aria-current": _ctx.isActive() && route.path,
        onClick: navigate
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_1)
    ]),
    _: 3
  }, 8, ["to"]))
}