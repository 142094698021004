
import "x4b-ui/dist/x4b-ui/x4b-ui.css";
import { defineComponent } from "vue";
import CheckRoles from "./components/CheckRoles.vue";
import CustomRouterLink from "./components/CustomRouterLink.vue";
import { expertisesRoles, historyRoles, myWalletRoles, walletsRoles } from "./models/role";
import getConfigValue from "./utils/configUtils";

export default defineComponent({
    name: "App",
    components: {
        "check-roles": CheckRoles,
        "custom-router-link": CustomRouterLink,
    },
    data() {
        return {
            appsUrl: getConfigValue("VUE_APP_APPS_URL"),
            appVersion: getConfigValue("VUE_APP_PRODUCT_VERSION") || "[PRODUCT_VERSION]",
            primaryColor: "#4c51bf",
            isMenuOpen: false,
            isBannerInitialized: false,
            myWalletRoles,
            expertisesRoles,
            walletsRoles,
            historyRoles,
        };
    },
    mounted() {
        (this.$refs.x4bui as HTMLElement).addEventListener("startupFinished", this.handleStartupFinished);
        (this.$refs.x4bui as HTMLElement).addEventListener(
            "menuToggleButtonClicked",
            this.handleMenuToggleButtonClicked
        );
    },
    unmounted() {
        (this.$refs.x4bui as HTMLElement).removeEventListener("startupFinished", this.handleStartupFinished);
        (this.$refs.x4bui as HTMLElement).removeEventListener(
            "menuToggleButtonClicked",
            this.handleMenuToggleButtonClicked
        );
    },
    methods: {
        handleMenuToggleButtonClicked(evt: Event) {
            this.isMenuOpen = (evt as CustomEvent<boolean>).detail;
        },
        handleStartupFinished() {
            this.isBannerInitialized = true;
        },
    },
});
