export enum Role {
    EXPERTISE_MANAGER = "EXPERTISE_MANAGER",
    SENIOR_MANAGER = "SENIOR_MANAGER",
    COMPANY_ADMINISTRATOR = "COMPANY_ADMINISTRATOR",
    CONSULTANT = "CONSULTANT",
    SOFTWARE = "SOFTWARE",
}

export const myWalletRoles = [
    Role.SOFTWARE,
    Role.CONSULTANT,
    Role.EXPERTISE_MANAGER,
    Role.SENIOR_MANAGER,
    Role.COMPANY_ADMINISTRATOR,
];
export const expertisesRoles = [Role.EXPERTISE_MANAGER, Role.SENIOR_MANAGER];
export const walletsRoles = [Role.EXPERTISE_MANAGER, Role.SENIOR_MANAGER, Role.COMPANY_ADMINISTRATOR];
export const historyRoles = [Role.EXPERTISE_MANAGER, Role.SENIOR_MANAGER, Role.COMPANY_ADMINISTRATOR];
export const adminOnly = [Role.COMPANY_ADMINISTRATOR];
